// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--d9a23";
export var animateScrollOut = "styles-module--animateScrollOut--13f2f";
export var colorSequence = "styles-module--colorSequence--749c5";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--6f088";
export var grow = "styles-module--grow--91613";
export var growAndShrink = "styles-module--growAndShrink--512aa";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--8f784";
export var moveBg = "styles-module--move-bg--4699f";
export var rotateLoop = "styles-module--rotateLoop--dfc54";
export var spin = "styles-module--spin--95b70";
export var spinCounter = "styles-module--spinCounter--8359a";